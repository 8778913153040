



































































import { mensajes_chat } from "@/shared/dtos/chat/mensajes_chat";
import mensajes_chatModule from "@/store/modules/mensajes_chat-module";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    MessageOperator: () => import("@/components/Chat/message-operator.vue"),
    MessageVisitor: () => import("@/components/Chat/message-visitor.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class chat_open extends Vue {
  @Prop({ default: "Chat" }) titulo!: string;
  @Prop({ default: "Conversación del chat" }) subtitulo!: string;
  @Prop({ default: 0 }) id_chat!: number;
  public mensaje: mensajes_chat = new mensajes_chat();
  public intelvalo = 0;
  public cantidad_mensajes = 0;

  created() {
    this.get_mensajes();
  }

  mounted() {
    this.intelvalo = setInterval(() => {
      this.get_mensajes_back();
    }, 5000);
  }

  get mensajes() {
    if (
      this.cantidad_mensajes < mensajes_chatModule.mensajes_chatsAllData.length
    ) {
      this.scroll_down();
    }
    this.cantidad_mensajes = mensajes_chatModule.mensajes_chatsAllData.length;
    return mensajes_chatModule.mensajes_chatsAllData;
  }

  get_mensajes() {
    mensajes_chatModule
      .getmensajes_chat_all_data(this.id_chat)
      .then(() => this.scroll_down());
  }
  get_mensajes_back() {
    mensajes_chatModule.getmensajes_chat_all_data_backgound(this.id_chat);
  }

  scroll_down() {
    setTimeout(function() {
      document
        .getElementById("conversation-group")!
        .scrollTo(
          0,
          document.getElementById("conversation-group")!.scrollHeight
        );
    }, 1002);
  }

  send_mensaje() {
    this.mensaje.id_incidencia = this.id_chat;
    mensajes_chatModule.guardarmensajes_chat(this.mensaje).then(() => {
      this.mensaje.mensaje = "";
      this.get_mensajes_back();
      this.scroll_down();
    });
  }

  destroyed() {
    clearInterval(this.intelvalo);
  }
}
